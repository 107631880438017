import { IconProps } from '@types'

export const WalletConnectIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="51"
      height="28"
      viewBox="0 0 51 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2053 5.79409C18.2943 -1.6449 31.4112 -1.6449 39.5016 5.79409L40.4738 6.68993C40.8794 7.06216 40.8794 7.66495 40.4738 8.03717L37.1446 11.0997C36.9418 11.2858 36.614 11.2858 36.4113 11.0997L35.0724 9.86776C29.4278 4.67741 20.2777 4.67741 14.6346 9.86776L13.1998 11.1872C12.997 11.3719 12.6693 11.3719 12.4665 11.1872L9.13589 8.12329C8.7331 7.75245 8.7331 7.14827 9.13589 6.77605L10.2053 5.79409ZM47.2059 11.5997L50.1948 14.2859C50.6017 14.6525 50.6017 15.2456 50.1948 15.6123L36.7224 27.718C36.3154 28.0847 35.6529 28.0847 35.246 27.718L25.6847 19.1262C25.5833 19.0346 25.4181 19.0346 25.3153 19.1262L15.754 27.718C15.3471 28.0847 14.6846 28.0847 14.2776 27.718L0.805213 15.6123C0.398262 15.2456 0.398262 14.6525 0.805213 14.2859L3.79276 11.6011C4.20109 11.2344 4.86221 11.2344 5.26916 11.6011L14.8318 20.1929C14.9332 20.2846 15.0985 20.2846 15.1999 20.1929L24.7611 11.6011C25.1694 11.2344 25.8306 11.2344 26.2375 11.6011L35.8001 20.1929C35.9015 20.2846 36.0668 20.2846 36.1682 20.1929L45.7308 11.6011C46.1378 11.2344 46.8003 11.2344 47.2072 11.6011L47.2059 11.5997Z"
        fill="#2F5CE2"
      />
    </svg>
  )
}
